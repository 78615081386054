<template>
  <div
  >
    <el-container>
      <el-aside width="250px">
        <customizeNavBar @addTabs="giveChildAddTabs" ref="bar"></customizeNavBar>
      </el-aside>
      <el-container>
        <el-header>
          <customizeTabs ref="tabs"></customizeTabs>
        </el-header>
        <el-main style="width: 100%">
          <transition name="fade">
              <router-view @addTabs="giveChildAddTabs"></router-view>
          </transition>
        </el-main>


      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavigationBar from "../layout/navigationBar/NavigationBar";
import Tabs from "../layout/tabs/Tabs";

export default {
  name: "Layout",
  components: {
    customizeNavBar: NavigationBar,
    customizeTabs: Tabs
  },
  data() {
    return {
      transitionName: 'transitionLeft',
      fullscreenLoading: true,

    }
  },
  methods: {
    giveChildAddTabs(targetName, path) {

      this.$refs.tabs.addTab(targetName, path);
      this.login = true;
      this.$message({
        message: "操作成功",
        center: true,
        duration: 1000
      })
    }

  },
  computed: {

  },
  beforeCreate() {
    if (!this.$store.getters.login()) {
      this.$router.push("/login")
    }


  },mounted() {
    document.onkeydown = function (e){
      let evt = window.event ||e;

      if (evt.code === 'F5'){
        if (evt.preventDefault) {
          evt.preventDefault();
        } else {
          evt.keyCode = 0;
          evt.returnValue = false
        }
      }
    }
}


}
</script>

<style>
.fade-enter-active, .fade-leave-avtive {
  transition: opacity 1.3s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

</style>