import axios from 'axios'
export default function request(configs, success, failure, token){
    const instance = axios.create({
        baseURL: 'http://tzzy.hnjsrcw.com/springboot-hntzzy/',////http://192.168.0.21:8090/  //http://tzzy.hnjsrcw.com/springboot-hntzzy/
        timeout: 100000
    })
    instance.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
    instance.interceptors.request.use(config =>{
        config.headers['authentication'] = token
        config.headers['responseType'] = "*/*";
        return config
    })

    instance(configs).then(
        res =>{

            success(res.data)

        }
    ).catch(err=>{
        failure(err)
    })
}