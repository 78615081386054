<template>
  <div class="middle-box text-center loginscreen  animated fadeInDown"
       style="width:400px">
    <div>
      <h2 class="yh">湖南省建筑施工特种作业
        <br>信息系统<br>
      </h2>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"  class="demo-ruleForm">
        <el-form-item  prop="account" >
          <el-input type="text" v-model="ruleForm.account" placeholder="帐号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item  prop="password" >
          <el-input type="password" v-model="ruleForm.password"  placeholder="密码" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"> 登陆 </el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>

<script>
import request from "@/util/request" ;

export default {

  name: "Login",
  data() {

    let validateAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入帐号'));
      } else {
        callback();
      }
    };
    let validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    return {
      fullscreenLoading: false,

      ruleForm: {
        account: '',
        password: '',
      },
      rules: {
        account: [
          {validator: validateAccount, trigger: 'blur'}
        ],
        password: [
          {validator: validatePassword, trigger: 'blur'}
        ]

      }
    };
  },created(){
    //this.ShowMessage();
  },
  methods: {
     submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
           request({
            url: '/login.do',
            method: "POST",
            async: false,
            data : {
              username: this.ruleForm.account,
              password: this.ruleForm.password
            },
          }, data=>{
              if (data.code === 200){
                this.$store.dispatch("login", [data.data.name, true, data.data.token,data.data.grantedAuthorities])
                sessionStorage.setItem("login", 'true')
                sessionStorage.setItem("^^", window.btoa(data.data.name));
                sessionStorage.setItem("^", window.btoa(data.data.token))
                for (let auth of data.data.grantedAuthorities) {
                  if ("ROLE_user:super:admin" === auth.authority) {
                    sessionStorage.setItem("admin", 'true')
                  }
                }
                this.$router.replace("/")
              }else{
                this.$message.warning(data.msg)

              }
          }, err =>{
             this.$message.error("密码错误")

          }, null)
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    ShowMessage() {
      this.$notify({
        title: '提示',
        type: 'warning',
        showClose: false,
        message: '系统已关闭，请切换至住建云使用新系统',
        duration: 0
      });
    }
  },


}
</script>

<style>
@import "../../assets/css/login.css";
@import "../../assets/css/style.css";
@import "../../assets/css/font-awesome.min.css";
</style>
