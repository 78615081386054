<template>
  <div class="tabs">
  <el-tabs v-model="editableTabsValue"
           type="card" closable
           @tab-remove="removeTab"
           @tab-click="tabClick"
  >
    <el-tab-pane
        v-for="item in editableTabs"
        :key="item.name"
        :label="item.title"
        :path="item.path"
        :name="item.name"
    >
    </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  data() {
    return {
      editableTabsValue: '首页',
      editableTabs: [
        {
          title: "培训批次列表",
          name: "培训批次列表",
          path: "/"
        }
      ]
    }
  },
  methods: {
    addTab(targetName, path) {
      let tabs = this.editableTabs;

      tabs.forEach((tab) =>{
        if (tab.name === targetName)
          this.editableTabsValue = tab.name;
      })
      if (targetName === this.editableTabsValue){
        this.tabClick(path)
        return ;
      }


      this.editableTabs.push({
        title: targetName,
        name: targetName,
        path: path
      });
      this.editableTabsValue = targetName;
      this.tabClick(path)
    },

    removeTab(targetName) {
      if (targetName === "培训批次列表")
        return ;
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
              this.$router.replace(nextTab.path)
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);
      this.$message({
        message: "操作成功",
        type: "success",
        duration: 1000

      })

    },
    tabClick(path){

     // let path = targetName.$vnode.data.attrs.path;
      if (typeof path === "object" && this.$route.path === path.$attrs.path){
          return ;
      }
      if (path === this.$route.redirectedFrom)
        return ;
      this.$router.replace(path)
    }
  }

}
</script>

<style>
.tabs{
  position: fixed;
  left: 250px;
  top: 0;
  right: 0;
}
</style>