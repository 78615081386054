<template>
  <div class="navigationBar ">
    <div>
      <br>
      <br>
      <span class="text-center" style="display: block;">
      <img alt="image" class="img-circle" src="../../assets/img/logo.jpg" width="89"/>
    </span>
      <a href="#" class="text-center" style="display: block">
        {{ this.$store.getters.currUsername() }}
      </a>
    </div>
    <el-menu default-active="1-4-1"
             class="el-menu-vertical-demo"
             @open="handleOpen"
             @close="handleClose"
             background-color="#545c64"
             text-color="#fff"
             active-text-color="#ffd04b">
      <el-menu-item :index="(index).toString()" :key="item.name" @click="addTabs(item.name, item.path)" v-for="(item, index) in menuss ">
        <i class="el-icon-tickets"></i>
        <span> {{ item.name }}</span>
      </el-menu-item>

    </el-menu>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  data() {
    return {

    };
  },
  methods: {
    addTabs(tabsName, path) {
      this.$emit("addTabs", tabsName, path);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  computed: {
    menuss(){
      if (!this.$store.getters.admin()) {
        return [
          {
            name: "证书查询",
            path: "/cert"
          },
          {
            name: "培训批次列表",
            path: "/"
          },
          {
            name: "年审批次列表",
            path: "/reviewBatchList"
          },
          {
            name: "修改密码",
            path: "/modifyPassword"
          },
          {
            name: "安全退出",
            path: "/exit"
          }

        ]
      }else{
        return [
          {
            name: "证书查询",
            path: "/cert"
          },
          {
            name: "培训批次列表",
            path: "/"
          },
          {
            name: "年审批次列表",
            path: "/reviewBatchList"
          },
          {
            name: "年审统计",
            path: "/reviewSta"
          },
          {
            name: "总体统计",
            path: "/overallStatistics"
          },
          {
            name: "修改密码",
            path: "/modifyPassword"
          },
          {
            name: "安全退出",
            path: "/exit"
          }

        ]
      }
    }
  },
  props: {
    menus: Array
  }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 250px;
  min-height: 400px;
}

.navigationBar {
  text-align: justify;
  width: 250px;
  height: 100%;
  background-color: #545c64;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  font-size: 20px;

}

.navigationBar a {
  display: table-cell;
  vertical-align: middle;
  color: aliceblue;
}

.navigationBar div {
  background-color: #293846;
  height: 200px;
}

</style>