import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: false,
    token: '',
    currUsername: '',
    loading: true,
    citys:[{
      name: "所有",
      value: "*"
    },{
      name: "长沙",
      value: "1"
    },{
      name: "株洲",
      value: "2"
    },{
      name: "湘潭",
      value: "3"
    },
      {
        name: "衡阳",
        value: "4"
      },
      {
        name: "邵阳",
        value: "5"
      },
      {
        name: "岳阳",
        value: "6"
      },
      {
        name: "常德",
        value: "7"
      },
      {
        name: "张家界",
        value: "8"
      },
      {
        name: "益阳",
        value: "9"
      },
      {
        name: "娄底",
        value: "10"
      },
      {
        name: "郴州",
        value: "11"
      },
      {
        name: "永州",
        value: "12"
      },
      {
        name: "怀化",
        value: "13"
      },
      {
        name: "自治州",
        value: "14"
      }
    ],
    personCount: 0,
    batchId:0,
    certReviewBatchId: 0,
    authorities: [],
    certState: "",
    trainCurrPage : 1,
    reviewCurrPage: 1,
    personCurrPage: 1,
    certReviewPage: 1,
    certTable: [],
    certForm: {
      name: '',
      idCard: '',
      year: '',
      certNo: '',
      typeOfWork: ""
    },
    admin: false

  },
  mutations: {

    setLogin(state, status){
      state.login = status;
    },

    getLogin(state){
      return state.login;
    },

    setToken(state, token){
      state.token = token;
    },

    getToken(state){
      return state.token;
    },

    setCurrUsername(state, currUsername){
      state.currUsername = currUsername;
    },

    getCurrUsername(state){
      return state.currUsername;
    },
    setPersonTable(state, personTable){
      state.personTable = personTable
    },
    setPersonCount(state, count){
      state.personCount = count;
    },
    setBatchId(state, id){
      state.batchId = id
    },
    setCertReviewBatchId(state, id){
      state.certReviewBatchId = id;
    },
    logout(state){
      state.certReviewBatchId = 0;
      state.token = null;
      state.batchId = 0;
      state.currUsername = "";
    },
    setAuthorities(state, authorities){
      state.authorities = authorities;
    },
    setState(state, certState){
      state.certState = certState;
    },
    setTrainCurrPage(state, currPage){
      state.trainCurrPage = currPage;
    },
    setReviewCurrPage(state, currPage){
      state.reviewCurrPage = currPage;
    },
    setPersonCurrPage(state, currPage){
      state.personCurrPage = currPage;
    },
    setCertReviewPage(state, currPage){
      state.certReviewPage = currPage;
    },
    setCertTable(state, table){
      state.certTable = table;
    },
    setCertForm(state, certForm){
      state.certForm = certForm;
    },
    setAdmin(state, admin){
      state.admin = admin;
    }


  },
  actions: {
    login(context, args){
      context.commit("setCurrUsername", args[0]);
      context.commit("setLogin", args[1]);
      context.commit("setToken",  args[2]);
      context.commit("setAuthorities", args[3]);
      for (let auth of args[3]) {
        if ("ROLE_user:super:admin" === auth.authority) {
          context.commit("setAdmin", 'true')
        }
      }

    },
    async  setPersonTable(context, args){
      context.commit("setState", args[0])
      context.commit("setBatchId", args[1])
    },
    async setCertReviewTable(context, args){
      context.commit("setCertReviewBatchId", args[0])
    },
    async setCertTable(context, table){
      context.commit("setCertTable", table)
    }


  },
  modules: {
  },
  getters:{
    isSuperAdmin(state){
        for (let auth of state.authorities) {
          if ("ROLE_user:super:admin" === auth.authority) {
            return true;
        }
      }
    },
    trainCurrPage(state){
      return state.trainCurrPage;
    },
    reviewCurrPage(state){
      return state.reviewCurrPage;
    },
    personCurrPage(state){
      return state.personCurrPage;
    },
    certViewCurrPage(state){
      return state.certReviewPage;
    },
    certTable(state){
      return state.certTable;
    },
    login:(state)=> () => {
      return sessionStorage.getItem("login");
    },
    currUsername:(state) => () => {
      return  window.atob(sessionStorage.getItem("^^"));
    },
    token:(state) => () => {
      return  window.atob(sessionStorage.getItem("^"));

    },
    admin:(state) => () => {
      return sessionStorage.getItem("admin");
    }
  },
})
