import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login'
import Layout from "@/layout/Layout";
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: 'index',
        component: () => import("../views/trainingBatchList/Index"),
        name: '培训批次列表',
      }
    ]
  },
  {
    path: "/cert",
    component: Layout,
    redirect: "/certQuery",
    children: [
      {
        path: "/certQuery",
        component: ()=> import("../views/certQuery/Index"),
        name: "证书查询"
      }
    ]
  },
  {
    path: "/reviewBatchList",
    component: Layout,
    redirect: "/review",
    children: [
      {
        path: "/review",
        component: ()=> import("../views/reviewBatchList/Index"),
        name: "年审批次列表"
      }
    ]
  },
  {
    path: "/overallStatistics",
    component: Layout,
    redirect: "/statistics",
    children: [
      {
        path: "/statistics",
        component: ()=> import("../views/overallStatistics/Index"),
        name: "总体统计"
      }
    ]
  },
  {
    path:"/reviewSta",
    component: Layout,
    redirect: "/reviewStatistics",
    children: [
      {
        path: "/reviewStatistics",
        component: ()=> import("../views/certReviewCountSta/Index"),
        name: "年审统计"
      }
    ]
  },

  {
    path: "/modifyPassword",
    component: Layout,
    redirect: "/modify",
    children: [
      {
        path: "/modify",
        component: ()=> import("../views/modifyPassword/Index"),
        name: "修改密码"
      }
    ]
  },
  {
    path: "/personList",
    component: Layout,
    redirect: "/person",
    children: [
      {
        path: "/person",
        component: ()=> import("../views/personnelView/Index"),
        name: "查看"
      }
    ]
  },
  {
    path: "/certReviewList",
    component: Layout,
    redirect: "/certReview",
    children: [
      {
        path: "/certReview",
        component: ()=> import("../views/certReview/Index"),
        name: "证书列表"
      }
    ]
  },
  {
    path: "/exit",
    component: Layout,
    redirect: "/logout",
    children: [
      {
        path: "/logout",
        component: ()=> import("../views/logout/Index"),
        name: "安全退出"
      }
    ]
  }
]

const router = new VueRouter({
  mode: "hash",
  base: "./",
  routes
})

export default router
